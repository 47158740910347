import logo_dark from "../assets/images/logo-dark.png";
import auth_img from "../assets/images/auth-img.jpg";
import logo from "../assets/images/logo.png";
import logo_sm from "../assets/images/logo-sm.png";
import flag_us from "../assets/images/flags/us.jpg";
import flag_germany from "../assets/images/flags/germany.jpg";
import flag_italy from "../assets/images/flags/italy.jpg";
import flag_spain from "../assets/images/flags/spain.jpg";
import flag_russia from "../assets/images/flags/russia.jpg";
import avatar_1 from "../assets/images/users/avatar-1.jpg";
import avatar_2 from "../assets/images/users/avatar-2.jpg";
import avatar_3 from "../assets/images/users/avatar-3.jpg";
import avatar_4 from "../assets/images/users/avatar-4.jpg";
import avatar_5 from "../assets/images/users/avatar-5.jpg";
import avatar_6 from "../assets/images/users/avatar-6.jpg";
import avatar_7 from "../assets/images/users/avatar-7.jpg";
import bg_profile from "../assets/images/bg-profile.jpg";
import shield from "../assets/images/svg/shield.gif";
import loader from "../assets/images/loader.gif";
import imageUpload from "../assets/images/imageUpload.png";
import play_store from "../assets/images/play-store.png";
import Screenshot_1 from "../assets/images/Screenshot1.png";
import Screenshot_2 from "../assets/images/Screenshot2.png";
import Frame_1 from "../assets/images/Frame_1.png";
import download_apk from "../assets/images/DownloadApk.png";
import Bajaj_Finserv from "../assets/images/brand/Bajaj_Finserv.png";
import HDFC_SKY from "../assets/images/brand/HDFC_SKY.png";
import HSBC_mf from "../assets/images/brand/HSBC_mf.png";
import Loot_Mogule from "../assets/images/brand/Loot_Mogule.png";
import money_control from "../assets/images/brand/money_control.png";
import MStock from "../assets/images/brand/MStock.png";
import PhonePe_Share_Market from "../assets/images/brand/PhonePe_Share_Market.png";
import SBI_Logo from "../assets/images/brand/SBI_Logo.png";
import tvs from "../assets/images/brand/tvs.png";
import Yes_Bank from "../assets/images/brand/Yes_Bank.png";
import app_2 from "../assets/images/app/1.jpg";
import app_1 from "../assets/images/app/2.jpg";
import app_3 from "../assets/images/app/3.jpg";
import app_4 from "../assets/images/app/4.jpg";
import app_5 from "../assets/images/app/5.jpg";
import app_6 from "../assets/images/app/6.jpg";
import app_7 from "../assets/images/app/7.jpg";
import app_8 from "../assets/images/app/8.png";
import app_9 from "../assets/images/app/9.png";
import app_10 from "../assets/images/app/10.png";

export const images = {
  logo_dark,
  app_2,
  bg_profile,
  app_8,
  app_1,
  app_9,
  logo_sm,
  app_10,
  app_4,
  loader,
  app_3,
  app_5,
  flag_italy,
  app_6,
  auth_img,
  app_7,
  imageUpload,
  Bajaj_Finserv,
  flag_us,
  HDFC_SKY,
  shield,
  HSBC_mf,
  flag_spain,
  Loot_Mogule,
  logo,
  flag_germany,
  flag_russia,
  money_control,
  avatar_1,
  MStock,
  avatar_2,
  avatar_3,
  PhonePe_Share_Market,
  avatar_4,
  avatar_5,
  SBI_Logo,
  avatar_6,
  avatar_7,
  tvs,
  play_store,
  Frame_1,
  Screenshot_1,
  Yes_Bank,
  Screenshot_2,
  download_apk,
};
